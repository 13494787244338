import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'
const { v4: uuidv4 } = require('uuid')

// App assets
import '../styles/index.css'
import homePageBestOfBest from '../assets/home-page/Homepage_Awards.jpg'
import clickHereHomePicture from '../assets/home-page/click-here-home-picture.png'
import checkOutUpcomingEvents from '../assets/home-page/check-out-our-upcoming-events.png'

// App components
import CustomLink from '../components/CustomLink'
import FreeReportWidget from '../components/FreeReportWidget'
import HomePagePhoto from '../components/HomePagePhoto'
import Layout from '../components/layout'

// App utils
import config from '../utils/config'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, presets } from '../utils/theme'

const styles = {
  sidebarImageHolder: {
    display: 'flex',
    flexDirection: 'column',
  },
  sidebarImage: {
    width: '100%',
    margin: 0,
    marginBottom: '10px',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  homePageDiv: {
    display: 'flex',
    flexDirection: 'column',
    [presets.Phablet]: {
      flexDirection: 'row',
    },
    [presets.Desktop]: {},
  },
  readersChoiceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  homePageSideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    padding: '10px',
    [presets.Phablet]: {
      width: '30%',
    },
    [presets.Desktop]: {
      width: '20%',
    },
  },
  homePageMainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    marginLeft: '20px',
    width: '100%',
  },
  readersChoiceImage: {
    width: '100%',
    maxWidth: '1000px',
    margin: 0,
    [presets.Desktop]: {
      maxHeight: '700px',
    },
  },
  readersChoiceImage2: {
    width: '100%',
    maxWidth: '1100px',
    margin: '0 0 0 50px',
    [presets.Desktop]: {
      maxHeight: '700px',
    },
  },
  upcomingEventsImage: {
    width: '100%',
    maxWidth: '1100px',
    margin: 0,
    [presets.Desktop]: {
      maxHeight: '300px',
    },
  },
  eventsCalenderText: {
    alignItems: 'center',
    textAlign: 'center',
  },
  reportDiv: {
    width: '100%',
  },
}

const PhotoSidebar = ({ images }) => (
  <div css={styles.sidebarImageHolder}>
    {images.map((i) => (
      <GatsbyImage
        image={i}
        css={styles.sidebarImage}
        key={uuidv4()}
        alt="home sidebar image"
      />
    ))}
  </div>
)

class Index extends React.Component {
  state = {
    show: false,
  }

  toggle = () => this.setState((currentState) => ({ show: !currentState.show }))

  render() {
    const video = ({ showMobile = false }) => {
      const showMobileStyles = showMobile
        ? { [presets.Desktop]: { display: 'none' }, display: 'block' }
        : {}
      return (
        <div
          id="collins-website-home-page-video"
          css={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '400px',
              margin: '0 auto',
              marginBottom: '10px',
            },
            showMobileStyles,
          ]}
        >
        </div>
      )
    }
    return (
      <Layout location={this.props.location}>
        <div id="collins-website-home-div" css={themeStyles.contentWidth}>
          {this.state.show && (
            <div className="popup" onClick={this.toggle}>
              <div className="popuptext">
                <img
                  alt="Event calendar hours"
                  src="https://res.cloudinary.com/collins-ortho/image/upload/v1689896862/events/events_calendar.png"
                  width="100%"
                  height="auto"
                ></img>
              </div>
            </div>
          )}
          <HomePagePhoto />
          <div id="home-page-main-div" css={styles.homePageDiv}>
            <div id="home-page-side-bar" css={styles.homePageSideBar}>
              <div id="home-page-upcoming-events">
                <img
                  alt="Check out our upcoming events"
                  css={styles.upcomingEventsImage}
                  src={checkOutUpcomingEvents}
                />
              </div>
              <div
                id="home-page-click-for-event-calendar"
                css={styles.donutPageBusinessHours}
              >
                <img
                  alt="Click here for events calendar"
                  css={styles.readersChoiceImage}
                  onClick={this.toggle}
                  src={clickHereHomePicture}
                />
              </div>
              <div
                id="home-page-events-calendar-text"
                css={styles.eventsCalenderText}
              >
                <p>
                  Join Collins Orthodontics for a spectacular smile celebration!
                  Every month we host family fun events showcasing dazzling
                  magicians, captivating balloon artists, lively music and more!
                  Stay tuned for future entertainment!
                </p>
              </div>
              <div
                css={{
                  display: 'none',
                  [presets.Phablet]: { display: 'block' },
                }}
              >
                {video({ showMobile: false })}
                <PhotoSidebar
                  images={[
                    _.get(
                      this.props,
                      'data.sidebarPhoto1.childImageSharp.gatsbyImageData'
                    ),
                    _.get(
                      this.props,
                      'data.sidebarPhoto2.childImageSharp.gatsbyImageData'
                    ),
                    _.get(
                      this.props,
                      'data.sidebarPhoto3.childImageSharp.gatsbyImageData'
                    ),
                    _.get(
                      this.props,
                      'data.sidebarPhoto4.childImageSharp.gatsbyImageData'
                    ),
                  ]}
                />
              </div>
            </div>
            <div id="home-page-main-content" css={styles.homePageMainContent}>
              <div id="cw-home-readers-choice-div">
                <img
                  alt="Collins Orthodontics Best of the Best Awards"
                  style={styles.readersChoiceImage2}
                  src={homePageBestOfBest}
                />
              </div>
              <div css={{ ...themeStyles.textPadding }}>
                <h1>
                  <span css={{ fontSize: rhythm(2), color: colors.brightBlue }}>
                    Orthodontist
                  </span>
                  <span css={{ fontWeight: 100, color: colors.brightBlue }}>
                    {' '}
                    in Rochester, MN{' '}
                  </span>
                </h1>
                <p>
                  Doctor offices are the worst. You smile because you know it’s
                  true. Painful, long wait times, missing lots of school and
                  work, expensive, the staff all hate their jobs, and you walk
                  out feeling worse about yourself than you did before. I know
                  because I’ve been there. But what if you could get a perfect
                  smile at an orthodontic office that was actually…fun? A place
                  where the staff loves their jobs and you can feel the positive
                  energy the second you walk through the doors? A place that
                  supports the community? A place that cares about making braces
                  affordable for your family? A place that actually cares about
                  minimizing dental pain and discomfort? If you are looking to
                  improve your smile and bite, and want to do it at an office
                  that not only cares about you getting a perfect smile, but
                  also cares about your overall experience, then Collins
                  Orthodontics might be the right fit for you.
                </p>
                <h2>Voted Best Orthodontist in Rochester, Minnesota</h2>
                <p>
                  For seven straight years, Collins Orthodontics has proudly
                  been voted by the Rochester community as the “Best
                  Orthodontist In Rochester” in the Rochester Post Bulletin’s
                  Best of the Best Awards (2015, 2016, 2017, 2018, 2019, 2020,
                  2021, 2022). So what does that mean for you? When it comes to
                  YOUR SMILE, you can rest assured that you (or your child) will
                  be receiving orthodontic care from Rochester’s #1 top voted
                  expert in braces, clear braces and invisalign.
                </p>
                <h2>Home of The Braces Guy ®</h2>
                <p>
                  Dr. Grant Collins is not only an expert in the Rochester area,
                  but his influence in orthodontics has a worldwide reach. He
                  has spoken at international orthodontic meetings, and goes by
                  the name The Braces Guy ® through social media channels such
                  as
                  {` `}
                  <CustomLink
                    target="_blank"
                    href={config.socialLinks.instagram}
                  >
                    Instagram
                  </CustomLink>
                  ,{` `}
                  <CustomLink target="_blank" href={config.socialLinks.tiktok}>
                    TikTok
                  </CustomLink>
                  , and
                  {` `}
                  <CustomLink target="_blank" href={config.socialLinks.youtube}>
                    YouTube
                  </CustomLink>{' '}
                  where his informational videos and posts have accumulated over
                  100 million likes, comments and shares, and over 5 million
                  subscribers.
                </p>
                <h2>Grab Some Donuts, Help A Kid Get Braces...</h2>
                <p>
                  Yes, you read that correctly...we do donuts too! Collins
                  Orthodontics is the proud home of{' '}
                  <CustomLink key="donut-page-link" to="/the-donut/">
                    "The Donut"
                  </CustomLink>
                  {` `}
                  which is our very own gourmet bakery that serves
                  fresh-to-order mini donuts with 17 different flavors, all
                  created by MasterChef finalist Shari Mukherjee! And the best
                  part of all - 100% of proceeds from every donut or beverage
                  purchased goes straight to our{' '}
                  <CustomLink
                    key="adopt-a-shark-link"
                    to="/contact-us/adopt-a-shark"
                  >
                    Adopt-A-Shark program that provides free braces to children
                    cancer survivors
                  </CustomLink>
                  . So be sure to scout out our{' '}
                  <CustomLink
                    key="donut-page-menu-link"
                    to="/the-donut/the-donut#the-donut-our-menu"
                  >
                    menu
                  </CustomLink>{' '}
                  and enjoy some donuts for a great cause!
                </p>
                <h2>Your Smile And Bite Are Priority #1</h2>
                <p>
                  Results matter, and at Collins Orthodontics, our entire focus
                  during your time in treatment will be on making sure your
                  smile and bite are nothing short of perfection. We care about
                  you getting exceptional results, and our staff is committed to
                  a level of excellence that routinely exceeds the expectations
                  of our patients.
                </p>
                <h2>Don't Miss School or Work</h2>
                <p>
                  Tired of the hassles of your child missing school or you
                  missing work for orthodontic appointments? How about
                  inconvenient parking ramps and limitations on the number of
                  days that your orthodontist is even available to see you in
                  clinic? You are not alone, and that’s why we offer appointment
                  times during early mornings, lunch hours and evenings - so
                  that you don’t have to always miss school or work for
                  orthodontic visits.
                </p>
                <h2>Receive Care From a Mayo Clinic-Trained Orthodontist</h2>
                <p>
                  Dr. Grant Collins is currently the only private practice
                  orthodontist in Rochester, MN who was trained at the Mayo
                  Clinic. Although there are many excellent orthodontic programs
                  across the country, Dr. Collins’ extensive training at the
                  Mayo Clinic allows him to handle anything from minor tooth
                  alignment issues to complex orthodontic-surgical cases. You
                  can be well assured that you are in great hands under the care
                  of Dr. Grant Collins with his excellent education and clinical
                  training.
                </p>
                <h2>
                  Get Straight Teeth Without Anyone Even Noticing That You Have
                  Braces
                </h2>
                <p>
                  One of the most common reasons why adults and teens are afraid
                  to finally do something about their smile is because they do
                  not want a mouth full of metal. The good news – you don’t have
                  to use metal to get straight teeth! In fact, we use the latest
                  technology that includes invisalign clear aligners and
                  Radiance clear braces so that you can improve your smile
                  without anyone even noticing.
                </p>
                <h2>Braces Can Be More Affordable Than You Think...</h2>
                <p>
                  The cost of braces is a touchy subject, but we choose to
                  address it head on with our patients. First of all,
                  your initial consultation at our office is free. This will
                  allow you to fully understand your treatment plan, overall
                  time in braces or invisalign, and the total cost of
                  treatment before even paying a dime. Next, we have flexible 0%
                  interest financing plans that allow braces to be more
                  affordable than ever. Our commitment to offering flexible
                  financing options allows us to find creative ways to “make it
                  work” for families that come from all types of financial
                  backgrounds.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    sidebarPhoto1: file(
      relativePath: { eq: "patientPics/SideBarPatientPics01.jpg" }
    ) {
      ...fluidImage400
    }
    sidebarPhoto2: file(
      relativePath: { eq: "patientPics/SideBarPatientPics02.jpg" }
    ) {
      ...fluidImage400
    }
    sidebarPhoto3: file(
      relativePath: { eq: "patientPics/SideBarPatientPics03.jpg" }
    ) {
      ...fluidImage400
    }
    sidebarPhoto4: file(
      relativePath: { eq: "patientPics/SideBarPatientPics04.jpg" }
    ) {
      ...fluidImage400
    }
    readersChoicePhoto: file(relativePath: { eq: "TeamPhoto2021.jpg" }) {
      ...fluidImage1000
    }
  }
`
